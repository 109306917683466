import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import img1 from '../images/Jau7Fz.jpg'
import img2 from '../images/Bp4SwH.jpg'
import img3 from '../images/1.jpg'
import img4 from '../images/IgS0Cz.jpg'
import img5 from '../images/wSobt6.jpg'
import img6 from '../images/501egn.jpg'
import img7 from '../images/bulletan.jpg'
import img8 from '../images/aytmGd.jpg'

const ruumiJagajad = () => (
  <Layout>
    <SEO title="Ruumijagajad" />
    
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
              <h1>RUUMIJAGAJAD</h1>
              <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
   </div>
   
   <div className="epoporandad" style={{
          maxWidth: 1300,
          margin: 'auto',
        }}>

<div className="wrapper-epo">
        <Link to='/ruumijagajad/voldikuksed/'>
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to='/ruumijagajad/lootsuksed/'>
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
          </Link>
        <Link to='/ruumijagajad/siirdeseinad/'>
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to='/ruumijagajad/voldikseinad/'> 
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
         <Link to='/ruumijagajad/referentsid/'>
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="ruumijagajad-wrapper">
        <div className="ruumijagajad-text">
            <h3>Ruumijagajad</h3>
            <p>Värvitud, lamineeritud pinnaga, klaasidega, spoonitud või enda disainitud- suur valiku võimalus
            Siirde- ja voldikseinu saab valmistada paljude erinevate pinnaviimistlustega. Elemente saab valmistada 
            paljudes erinevates värvitoonides, kõrgsurvel lamineeritud pinnaga, naturaalse puiduspooniga, kriidi- 
            või dushitahvlipinnaga, klaasidega jpm. Elementide pindasid on võimalik kujundada ka ise,ühendada erinevaid materjale
            ja pinnaviimistlusi. Voldikseinad, siirdeseinad,liigendseinad,teisaldatavad vaheseinad…</p>
        </div>
        <div className="subpage-img ruumijagajad-img">
            <div className="ruumijagajad-img1">
              <img src={img7} alt="Bulletan" width="70"></img>
              <p>Bulletän-tahvli pinnaga</p>
            </div>
            <div className="ruumijagajad-img2">
              <img src={img5} alt="Klaasidega" width="70"></img>
              <p>Klaasidega</p>
            </div>
            <div className="ruumijagajad-img3">
              <img src={img1} alt="Spoon" width="70"></img>
              <p>Spoon, kõik puiduliigid</p>
            </div>
            <div className="ruumijagajad-img4">
              <img src={img4} alt="Enda kujundus" width="70"></img>
              <p>Enda kujundusega</p>
            </div>
            <div className="ruumijagajad-img5">
              <img src={img2} alt="Koik toonid" width="70"></img>
              <p>Värvitud kõikides toonides</p>
            </div>
            <div className="ruumijagajad-img6">
             <img src={img6} alt="Kriidi/dushi pinnaga" width="70"></img>
              <p>Kriidi-, või dushitahvli pinnaga</p>
            </div>
            <div className="ruumijagajad-img7">
             <img src={img8} alt="Laminaat" width="70"></img>
              <p>Kõrgsurve laminaat, 1mm paksune</p>
            </div>
            <div className="ruumijagajad-img8">
              <img src={img3} alt="Melamiin" width="70"></img>
              <p>Melamiin, õhuke laminaat</p>
            </div>
        </div>
      </div>
  </div>

  </Layout>
)

export default ruumiJagajad